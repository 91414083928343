import React, { Component } from 'react'
import { Link, navigate } from 'gatsby'
import BrandLogo from './../../assets/images/brand_logo_1.png'
import HeartWhite from './../../assets/images/noun_Heart.png'
import HeartActive from './../../assets/images/heart_active.png'
import NoFavoriteImg from './../../assets/images/noun_Heart_favret.png'
import Cookies from 'universal-cookie'
import axios from 'axios'
import _ from 'lodash'
import { Button } from 'reactstrap'
import { HOSTNAME, IMG_HOSTNAME } from '../config'
const cookies = new Cookies()

let initialState = {
  user: {},
  isLoading: false,
}

class Favorites extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  componentWillMount() {
    this.setState({ isLoading: true })
  }

  componentDidMount() {
    const user = cookies.get('user')
    if (user) {
      this.getUser(user._id)
      this.setState({ user: user })
      this.getBrands()
      setTimeout(function() {
        document.title = 'Favorites | American Field'
        let meta = document.getElementsByTagName('meta')
        for (var i = 0; i < meta.length; i++) {
          if (meta[i].name.toLowerCase() === 'description') {
            meta[i].content = 'Favorites | American Field'
          }
          if (meta[i].name.toLowerCase() === 'url') {
            meta[i].content = 'https://app.shopaf.co/favorites'
          }
        }
      }, 300)
    } else {
      // navigate('/login')
    }
  }

  getBrands() {
    let self = this
    axios
      .get(HOSTNAME + 'getBrands')
      .then(function(response) {
        let res = response.data
        if (res.success) {
          self.props.brands(res.data)
        }
      })
      .catch(function(error) {
        self.props.brands([])
      })
  }

  getUser(id) {
    let self = this
    let obj = { id }
    axios
      .post(HOSTNAME + 'getUser', obj)
      .then(function(response) {
        let res = response.data
        if (res.success) {
          let expires = new Date()
          expires.setHours(expires.getHours() + 24)
          expires = new Date(expires)
          cookies.set('user', res.data, { path: '/', expires })
          self.setState({ user: res.data, isLoading: false })
        }
      })
      .catch(function(error) {
        self.setState({ user: {}, isLoading: false })
      })
  }

  selectBrand(val) {
    const { user } = this.state
    window &&
      window.ga &&
      window.ga('send', 'event', {
        eventCategory: `Brand details - ${val.title}`,
        eventAction: 'Brand details navigate',
        eventLabel: `Brand details - ${val.title}`,
        transport: '1',
      })
    let page_url = String(val.title).toLowerCase()
    if (val.title !== 'Krammer + Stoudt') {
      page_url = page_url.replace(/ /g, '-')
    } else {
      page_url = 'krammer-stoudt'
    }
    navigate(page_url, {
      state: { brand: val, fav: _.get(user, 'favorites', []) },
    })
  }

  selectFavorites(val) {
    window &&
      window.ga &&
      window.ga('send', 'event', {
        eventCategory: 'Favorite | Unfavorite brand',
        eventAction: 'Favorite | Unfavorite brand clicked',
        eventLabel: 'Favorite | Unfavorite brand clicked',
        transport: '1',
      })
    let self = this
    const user = this.state.user
    let obj = {}
    obj.userId = user._id
    obj.brandId = val._id
    axios
      .post(HOSTNAME + 'selectFavorite', obj)
      .then(function(response) {
        let res = response.data
        if (res.success) {
          self.getUser(user._id)
        } else {
        }
      })
      .catch(function(error) {
        self.setState({ resMessage: error, resType: 'alert alert-danger' })
      })
  }

  render() {
    const { user, isLoading } = this.state
    let fav = _.get(user, 'favorites', [])
    const { searchTerm } = this.props
    fav = fav.filter(function(u) {
      return u.title && u.title.toLowerCase().match(searchTerm.toLowerCase())
    })
    return (
      <div>
        {isLoading ? (
          <div className="loader__section">
            <div className="loading" />
          </div>
        ) : (
          <div id="wrappper" className="favorites__page page">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="brands__section">
                    <ul className="brands__list">
                      {fav && fav.length > 0 ? (
                        fav.map((val, index) => {
                          let description = val.description
                            ? val.description.substring(0, 140)
                            : ''
                          return (
                            <li className="brands__list--item">
                              <div
                                onClick={this.selectFavorites.bind(this, val)}
                                className="hart__icon"
                              >
                                <img src={HeartActive} />
                              </div>
                              <div
                                onClick={this.selectBrand.bind(this, val)}
                                className="brands_description"
                                style={{
                                  backgroundImage:
                                    'url(' +
                                    IMG_HOSTNAME +
                                    _.get(val, 'image', '') +
                                    ')',
                                }}
                              >
                                <div className="brands_logo">
                                  <img
                                    src={IMG_HOSTNAME + _.get(val, 'logo', '')}
                                  />
                                </div>
                                <div className="description_text">
                                  <h2>{_.get(val, 'title', '')}</h2>
                                  <p>
                                    {description}... <strong>Read More</strong>
                                  </p>
                                </div>
                                <div className="overlay" />
                              </div>
                            </li>
                          )
                        })
                      ) : (
                        <div className="favorites__blanck">
                          <h2 className="favorites__title">No Favorites</h2>
                          <div className="favorites__blanck--img">
                            <img src={NoFavoriteImg} />
                          </div>
                          <Link to={'/'}>
                            <button className="btn ticket_btn purchase">
                              View Brands
                            </button>
                          </Link>
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Favorites
